import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PreviewContainer = styled.div`
  margin: 1% 20%;
  padding: 15px;
  text-align: left;
//   border: 1px solid #ddd;
//   border-radius: 8px;
//   background-color: #f9f9f9;

  @media (max-width: 900px) {
    margin: 1% 15%;
  }
`;

const PreviewTitle = styled.h2`
  color: #333;
  font-size: 1.5em;
`;

const PreviewText = styled.p`
  color: #666;
  font-family: PublicoText, Georgia, Cambria, Times New Roman;
`;

function StimulatingVCMPreview() {
  const title = "Stimulating the Voluntary Carbon Market";
  const previewText = "Exploring innovative approaches to drive demand in the Voluntary Carbon Market beyond large corporations, and the importance of public engagement in scaling carbon removal efforts.";

  return (
    <PreviewContainer>
      <PreviewTitle>{title}</PreviewTitle>
      <PreviewText>{previewText} </PreviewText>
      <Link to="/Stimulating-the-Voluntary-Carbon-Market">(Read More)</Link>
    </PreviewContainer>
  );
}

export default StimulatingVCMPreview;