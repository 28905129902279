import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Assuming you are using react-router for navigation

const BlogPostContainer = styled.div`
    margin: 1% 20%;
    text-align: left;
    // padding: 15px;
    // border: 1px solid #ddd;
    // border-radius: 8px;
    // background-color: #f9f9f9;

    @media (max-width: 900px) {
        margin: 1% 15%;
    }
`;

const BlogPostTitle = styled.h2`
    color: #333;
`;

const BlogPostPreview = styled.p`
    color: #666;
    font-family: PublicoText, Georgia, Cambria, Times New Roman;
`;

function DecPost({linkToFullPost }) {
    const title = "Climate Optimism and Facilitating Technological Progress"
    const previewText= "It is easy to get caught up in the prevailing narrative that as a planet, we are headed for titanic collapse. I would like to be one of the few operators in climate-tech to reject this notion.  "

    return (
        <BlogPostContainer>
            <BlogPostTitle>{title}</BlogPostTitle>
            <BlogPostPreview>{previewText}</BlogPostPreview>
            <Link to='/Jan2024'>(Read More)</Link>
        </BlogPostContainer>
    );
}

export default DecPost;
