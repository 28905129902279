import React from 'react';
import styled from 'styled-components';
import Links from './Links';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8; // You can choose your color
  padding: 10px 20px;
  max-width: 100%;

  &media (min-width: 900px) {
    padding: 15px 50px;
    // margin: 100px 100px;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 20px;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: #333; // Choose your color
  font-weight: bold;
  &:hover {
    color: #666; // Change on hover
    text-decoration: none;
  }
`;

const SocialMediaLinks = styled.div`
  display: flex;
  gap: 20px;
`;

// You can add more styling as needed

function Header() {
    return (
        <HeaderContainer>
            <NavLinks>
                <NavLink href="/">Home</NavLink>
              {/* should have projects lead to recent works area - need to use #? */}
                <NavLink href="/">Projects</NavLink>
                <NavLink href="http://www.carbonsmith.io">Carbonsmith</NavLink>
            </NavLinks>

            <SocialMediaLinks>
                <Links />
            </SocialMediaLinks>
        </HeaderContainer>
    );
}

export default Header;
