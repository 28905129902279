import React from 'react';
import { 
    Link
} from 'react-router-dom';
import '../App.css';
import fullview from '../photos/fullview.png';
import relatedpic from '../photos/relatedsongs.png';
import search_function from '../photos/search_function.png';
import initial_pca from '../photos/Initial_pca.png';
import find_closest from '../photos/find_closest.png';
import graphing from '../photos/graphing.png';
import Header from '../Header';
import styled from 'styled-components';

const DateDiv = styled.div`
    margin-right: 70%;
`

const HeaderDiv = styled.div`
    margin-bottom: 0;
`

const BackButton = styled.div`
    text-align: center;

`


const Post = () => {
    const date = 'Date: May 7 2021';
    const spotify = {
        id: 'SpotifyProject',
        title: 'A New Type of Recommender System for Your Spotify Account',
        p1: 'I am interested in altering the way that we discover new songs. Instead of taking a genre-based approach to recommendations, consider strictly the audial properties of the song itself.',
        p2: 'Data provided to us by Spotify such as "instrumentalness", "acousticness","tempo" along with several other features can help us do this, without even having a genre tag. First, we needed the data. For this project, I actually used a dataset provided by Kaggle rather than reaching the Spotify APIs. This was likely not the best way to do this but it made starting up the project a bit easier. I found all data ',
        kagglelink: 'here',
        p3: '. After brief analysis of the features and data available, I determined that it would be great if we could try and crunch the different features down to 3 dimensions in order to create a visual component that users could navigate. In order to do so, I used principal component analysis, and then mapped each of the songs features to the coordinates of this new eigenspace.',
        p4: 'After this, I looped through the entire dataframe (~170k songs) to map every song to this new 3 dimensional basis. The code for this is a site for sore eyes, but if you are really interested it can be found on my ',
        github: 'github',
        p4cont: '. After this mapping was complete - I wrote the new x,y,z coordinates for each song to a new CSV so that I could read an entirely new dataframe with strictly these values.',
        newdf: 'With our finalized data, the next step was to begin to create a search function so that users could actually find songs that were relevant to them. The goal was to take user input, and search through the dataframe. Once a song was chosen, it would call a function named "find_closest_5" that would calculate the distance of the 5 closest songs within our new eigenspace, as those 5 songs would likely be the songs with the most features in common. Essentially, we are using proximity in our new 3 dimensional space as a means to measure true similarity between songs.',
        postsearch: "Even though our initial search was finished, because there were so many song choices, often times an artist was needed to be manually chosen to match the correct song. To do this, I downloaded a package called Inquirer, and passed in the artists for all results with the same song name and had the user choose which exact song they we're hoping to search for. If the song they searched was an exact match, this step was ignored.",
        findclosest: "Next came our find_closest function. I actually set it up so that we would find the closest 100 songs in our eigenspace and return them in a dataframe sorted by proximity. This way we could plot other close points, but separate the closest 5 when plotting through a simple df.head(5) call.",
        plotlystart: "Finally, I used plotly, and specificially plotly.graph_objs 3DScatter to build the interactive graph that mapped 500 random songs, the 100 closest songs, and the 5 closest songs - noteably distinguished in Red, with red vectors pointing out from the chosen song towards these red dots in the PCA-generated eigenspace.",
        closing1: "I found this system to work surprisingly well in terms of finding songs that were similar to one another! Because it is computing and searching across over 100k rows in a dataframe, currently the runtime is not ideal (takes around 6 minutes to run). I will be working to speed that up quite a bit, and hopefully can achieve runtime of < 30 seconds in the next few weeks. I found this project to be a blast to work on, and it also helped my technical skills in both data science and data engineering.",
        closing2: "Once runtime has been sped up, I will deploy the project to this site so everyone can give it a go. The algorithm I have used gives the potential to easily find new songs that may not have been in the scope of your genre or time-period, but should generally capture the same vibe as the song you have searched! Furthermore, the graphing component will give you a 3-dimensional space to quite literally 'explore' for new songs in an intuitive way. I personally find this to be really cool as it allows you to traverse a more diverse collection of music, and hope that when it's posted, you find it cool as well.",
        finalclosing: " Thanks for giving this a read. I hope you may have found this interesting! - Drew"




    }


    return (
        <div>
            <Header />
        <main className='PostMain'>
        <div className='Post'>
            <HeaderDiv>
            <h1 className='Posttitle'>{spotify.title}</h1>
            </HeaderDiv>
            <DateDiv>
            <p>{date}</p>
            </DateDiv>
            <br />
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{spotify.p1}</p>
            <p>{spotify.p2}<a className='Links' href='https://www.kaggle.com/yamaerenay/spotify-dataset-19212020-160k-tracks'>{spotify.kagglelink}</a></p>
            <p>{spotify.p3}</p>
            <br />
            <br />
            <div className='ImageDivSpotify'>
            <img src={initial_pca} alt='code snippet for generating PCA' className='SpotifyImage'/>
            </div>
            
            <br />
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{spotify.p4}<a className='Links' href='https://github.com/drewhahn23/BasicFcnsSpotifyOverlay/blob/main/init_data_analysis.py'>{spotify.github}</a>{spotify.p4cont}</p>
            <p>{spotify.newdf}</p>
            <br />
            <br />
            <div className='ImageDivSpotify'>
            <img src={search_function} alt='code snippet for search function' className='SpotifyImage'/>
            </div>
            <br />
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{spotify.postsearch}</p>
            <p>{spotify.findclosest}</p>
            <br />
            <br />
            <div className='ImageDivSpotify'>
            <img src={find_closest} alt='code snippet for find_closest function' className='SpotifyImage'/>
            </div>
            <br />
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{spotify.plotlystart}</p>
            <br />
            <br />
            <div className='ImageDivSpotify'>
            <img src={graphing} alt='code snippet for graph creation' className='SpotifyImage'/>
            </div>
            <div className='ImageDivSpotify'>
            <img src={fullview} alt='full 3D view' className='SpotifyImage'/>
            </div>
            <div className='ImageDivSpotify'>
            <img src={relatedpic} alt='zoom in on vector connections' className='SpotifyImage'/> 
            </div>
            <br />
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{spotify.closing1}</p>
            <br />
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{spotify.closing2}</p>
            <p>{spotify.finalclosing}</p>





            <br />
            <br />
            <BackButton>
            <Link to='/'>Back</Link>
            </BackButton>

                {/* <Route exact path='/' /> */}

        </div>
        </main>
        </div>
        
    );

};



export default Post;