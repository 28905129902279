import React from 'react';
import Projects from '../Projects.js';
import '../App.css';
import PostPreview from '../PostPreview.js';
import PlanetPreview from '../PlanetPreview.js';
import SolarSystem from './ThreeJs.js';
import DecPost from './BlogPosts/DecPost.jsx';
import Header from '../Header.jsx';
import StimulatingVCMPreview from './BlogPosts/StimulatingVCMPost/StimulatingVCMPreview.jsx';


function Homepage() {
// const Homepage = () => {
    

    return (
        <main>
        <Header />
        <div className='PostPreviewHeaderDiv'>
        <h1 className='WelcomeHeader'>Welcome!</h1>
        </div>
        <div className='PostPreviewHeaderDiv'>
        <h3>I'm Drew - founder of Carbonsmith and a full-time MBA candidate at the UCLA Anderson School of Management.</h3>
        <h3>I have a few older projects that are posted on here, and am hoping to transform this site into a blog where I can share some of my thoughts on entrepreneurship, climate tech, and investing. Thank you for visiting!</h3>
        </div>

        <Projects />

        <StimulatingVCMPreview />

        <DecPost />

        <PlanetPreview />

        <PostPreview />

        <SolarSystem />


        <br />
        </main>

    );

};

export default Homepage;