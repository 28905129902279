import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Header from '../../../Header'; // Adjust the import path as needed
import pitch_photo from '../../../photos/CleanLeapPitchPic.JPG';
import mckinsey_study_cdr from '../../../photos/McKinsey_Graphic.png';
import first_insight_graphic from '../../../photos/FirstInsightGraphic.png';
import cdr_fyi_sales from '../../../photos/CDRFYI_Sales.png';
import climatefocus_report from '../../../photos/ClimateFocus_report.png';
import ogImage from '../../../photos/naturephoto_article_stimulatingvcm.png';

const PostContainer = styled.div`
  max-width: 70%;
  margin: auto;
  padding: 20px;
  font-family: 'Helvetica', 'Arial', sans-serif;

  @media (max-width: 900px) {
    max-width: 87%;
  }
`;

const PostTitle = styled.h1`
  color: #333;
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
`;

const PostParagraph = styled.p`
  color: #444;
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: PublicoText, Georgia, Cambria, Times New Roman;

  &:first-letter {
    margin-left: 20px;
  }
    &:after {
        content: "";
        display: block;
        margin-top: 10px;
    }
`;

const SubHeading = styled.h2`
  color: #555;
  font-size: 1.5em;
  margin-top: 30px;
  margin-bottom: 15px;
`;

const ImageContainer = styled.div`
  max-width: 75%;
  margin: 20px 0;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const ImageCaption = styled.p`
  font-size: 0.9em;
  color: #666;
  text-align: left;
  margin-top: 5px;
`;

const DualImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SingleImageContainer = styled.div`
  width: 48%; // Adjust this value to control the gap between images
  
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const SharedImageCaption = styled.p`
  font-size: 0.9em;
  color: #666;
  text-align: center;
  margin-top: 10px;
`;

function DualImageWithCaption({ image1, image2, caption }) {
  return (
    <>
      <DualImageContainer>
        <SingleImageContainer>
          <StyledImage src={image1.src} alt={image1.alt} />
        </SingleImageContainer>
        <SingleImageContainer>
          <StyledImage src={image2.src} alt={image2.alt} />
        </SingleImageContainer>
      </DualImageContainer>
      <SharedImageCaption dangerouslySetInnerHTML={{ __html: caption }} />
    </>
  );
}


function StimulatingVCMPost() {
  const postTitle = "Stimulating the Voluntary Carbon Market";
  const postDescription = "Exploring innovative approaches to drive demand in the Voluntary Carbon Market beyond large corporations, and the importance of public engagement in scaling carbon removal efforts.";
  const postUrl = 'https://drewhahn.me/Stimulating-the-Voluntary-Carbon-Market';

  return (
    <div>
      <Helmet>
        <title>{postTitle}</title>
        <meta property="og:title" content={postTitle} />
        <meta property="og:description" content={postDescription} />
        <meta property="og:url" content={postUrl} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:type" content="article" />
      </Helmet>
      <Header />
      <PostContainer>
      <PostTitle>Stimulating the Voluntary Carbon Market</PostTitle>

        <SubHeading><i>General Thoughts</i></SubHeading>

        <PostParagraph>
        In starting this, I'd like to acknowledge that it has been a crazy couple of months. I moved into a new home a little over a month ago and have spent a total of 4 days there. Between travel for work, pitching for competitions in Palo Alto, attending <a href="https://www.linkedin.com/search/results/all/?keywords=%23climateweeknyc&origin=GLOBAL_SEARCH_HEADER&sid=wXC" target="_blank" rel="noopener noreferrer">#ClimateWeekNYC</a>, and trying to take some semblance of a vacation with my partner in between, along with classes in my MBA program starting up again this week, I'm stretched pretty thin. That said, this period of time has been extremely formative for me. I'm grateful for the lessons that have been learned, the experience I've gained, and the greater understanding I've developed in terms of what it means to scrap for every inch in building a business.
        </PostParagraph>

        <PostParagraph>
        More important than my own personal growth, through the various events I've attended over the past month I've developed a refined sense of the present scope of viewpoints in the carbon removal space. Seeing an amalgamation of the spectrum of opinions and perspectives has further shaped my own. There is a sense of unified approach to a problem in this community. While a unified approach is often positive for steady growth in an industry, to a degree it also makes me wary. My own sense is that a unified solution front is a necessary but insufficient piece of the equation in tackling the goal of increasing carbon removal broadly to gigaton scale by the 2040's. For rapid growth and adoption of any industry on a global scale, massive individual growth outliers are necessary. And to me it is quite apparent that outliers are difficult to foster in environments of uniformity. Overcoming uniformity and fostering creativity is required to catalyze any dreams of an overnight growth phase in carbon removals and properly stimulate the voluntary carbon market.
        </PostParagraph>
        <DualImageWithCaption
                image1={{
                    src: cdr_fyi_sales,
                    alt: "CDR.fyi graphic on total engineered carbon removal sales"
                }}
                image2={{
                    src: climatefocus_report,
                    alt: "Total progression of the holistic voluntary carbon market."
                }}
                caption={`
                    Courtesy: <a href="https://cdr.fyi" target="_blank" rel="noopener noreferrer">CDR.fyi</a> (left) and 
                    <a href="https://alliedoffsets.com/wp-content/uploads/2023/11/VCM-Forecast-Report-November-2023.pdf" target="_blank" rel="noopener noreferrer">Climate Focus</a> (right).
                    There is clearly growing interest in engineered and high quality carbon removal. However, the numbers pale in comparison to the broader VCM. Stimulating further interest in engineered carbon removal is paramount for permanent reductions.
                `}
                />

        <SubHeading><i>The Core Problem</i></SubHeading>

        <PostParagraph>
        This essay is called stimulating the Voluntary Carbon Market (VCM) for a reason. The core of the problem is - how do we drive demand beyond companies that are making tens-to-hundreds of billions in revenue and foster concern about the quality of credits and off-takes that they may involve themselves with? I don't have a well-rounded solution, but I do have a prescription for adjusting our thought process to trigger novel and powerful demand signals from the market. This prescription is not terribly complicated but requires pulling away from the masses and getting back to first-principle thinking on what the drivers of incentives are, and how we can manage those in our favor with the goal of growing carbon removal in mind. I'll get to that in a bit.
        </PostParagraph>

        <PostParagraph>
        Today there are nearly 1000 engineered carbon removal companies in operation - with nearly 80% of them starting up in the past two years. As <a href="https://www.linkedin.com/in/mowgli-holmes/" target="_blank" rel="noopener noreferrer">Mowgli Holmes</a>, Science Director of Cur8 noted at the Santander Carbon Removal Capital Summit, CapEx intensive companies running up to the two year mark means one major issue: these companies are all in simultaneous need of continued funding in order to grow - and they will be in need of this funding soon. Unfortunately, at current levels of global compliance support and existing demand from the VCM, there is likely to be a consolidation event in the near future. This is not to be unexpected from an industry where supply-side has seen rapid expansion while buy-side demand has lagged behind. The thinking that evolves as a result is something to the tune of, <i>"How can we get new corporate buyers to give a damn about scaling and taking bets on these companies?"</i>
        </PostParagraph>

        <SubHeading><i>A Problem With Thinking</i></SubHeading>

        <PostParagraph>
        <i>"We need to lower risk for buyers."</i> <br />
        <i>"We need more data."</i> <br />
        <i>"We need powerful monitoring, reporting and verification (MRV)."</i> <br /> <br />
        All of this is true. These 3 statements represent the majority of existing lines of thought regarding how to overcome temporary lapses in demand markers. Most suppliers and intermediaries are thinking the same way, meaning that most companies are building the same products, meaning we are experiencing redundancy - not necessarily in the supply of carbon removal generation, but rather in the commercialization and ecosystem development that is attempting to round out capital allocation towards this industry.
        </PostParagraph>

        <PostParagraph>
        It's enticing to think about effective tools for the growth of other, similar industries - such as solar and wind. I'll claim that this is another faulty comparison as the goals with industry development are entirely different for carbon removal. Solar and wind are operating on a totally separate time horizon with a 15-year head start towards scale. Creative project financing, development of robust investment strategy and increased bankability of projects, and evolution of industry breadth across large and small scale operations worked for solar - and we need these for carbon removal as well. But once again - while these tools are necessary, they are not sufficient.
        </PostParagraph>

        <SubHeading><i>A New Approach</i></SubHeading>

        <PostParagraph>
        I thought <a href="https://www.linkedin.com/in/susansu/" target="_blank" rel="noopener noreferrer">Susan Su</a> of Toba Capital had the most salient point of the entire Carbon Removal Capital Summit in San Francisco. When asking a group of 400 carbon removal industry experts from around the world, "How many of you looked at the available options to purchase carbon credits to offset your flight here?" - she was met with more than a dulled response. Maybe 2% of the audience had considered such a purchase. In a room of some of the most knowledgeable people in the world on carbon markets, almost none were interested in purchasing credits to offset their own travel footprint. How could this be the case?
        </PostParagraph>

        <PostParagraph>
        I'll offer a simple answer: <i>we have terrible product implementation around carbon markets. </i>
        For those in the industry who wonder, "How can we stimulate the VCM?", I propose a new idea: <i>Look inwards.</i> If the most knowledgeable of us aren't even considering using existing products, there is an issue with the products themselves.
        </PostParagraph>

        <PostParagraph>
        Companies - especially public companies - have a fiduciary duty to their investors and customers. I am not proposing that we stop trying to develop tools to help corporations make sense of getting involved in carbon removal, but I am saying that a robust solution involves creating a system where <i>everyone cares</i> about making carbon removal work. We don't need a hundred more iterations of corporate tools for maximizing efficient investment - these are already being built by dozens of companies. What we need are tools to enhance public education as to why carbon removal is so important for long-term sustainable development goals, as well as powerful narratives to help companies understand that, not only does investment in carbon removal through credits make sense in terms of impact, but in terms of financial performance as well. No industry grows to $100B overnight without strong public interest. There are no exceptions.
        </PostParagraph>

        <ImageContainer>
            <StyledImage src={mckinsey_study_cdr} alt="McKinsey study on carbon dioxide removal" />
            <ImageCaption>
                Courtesy: <a href="https://www.mckinsey.com/capabilities/sustainability/our-insights/sustainability-blog/matching-durable-carbon-removals-supply-and-demand-by-2030" target="_blank" rel="noopener noreferrer">McKinsey</a>. Not only does durable CDR make sense from the perspective of public facing financial prospects, but also in terms of the potential for a pending supply crunch of available CDR.
            </ImageCaption>
        </ImageContainer>

        <PostParagraph>
        Unfortunately, educating the public is not a traditionally lucrative investment opportunity. This is why we need highly creative product development. There is no doubt that gen-z and millennials care about addressing net-zero goals. We need to put them in position to demonstrate their partiality in a low-friction manner without interrupting their daily lives.
        </PostParagraph>

        <PostParagraph>
        According to <a href="https://pditechnologies.com/blog/tracking-gen-z-consumer-trends-sustainability/" target="_blank" rel="noopener noreferrer">PDI Technologies</a>, 77% of Gen-Z consumers are willing to pay more for sustainable products and services. Similarly, sustainability messaging tends to be most effective with this group when it occurs digitally, especially at point of sale. I point out these statistics to signify that a traditional marketplace is not the only way to build demand in the VCM. By driving demand through the consumer, we can amplify interest from corporates that have more buying power, as well as give them tools to leverage true consumer preferences more effectively.
        </PostParagraph>

        <ImageContainer>
            <StyledImage src={first_insight_graphic} alt="First insight study on sustainable products" />
            <ImageCaption>
                Courtesy: <a href="https://www.firstinsight.com/press-coverage/how-gen-z-is-influencing-all-generations-to-make-sustainability-first-purchase-decisions" target="_blank" rel="noopener noreferrer">First Insight</a>. There has been unprecedented growth across all cohorts in terms of willingness to pay moderate green premiums.
            </ImageCaption>
        </ImageContainer>

        <SubHeading><i>Tying it Together</i></SubHeading>

        <PostParagraph>
        First principles thinking helps us break down complex problems into their fundamental parts. We know what carbon removal needs, what corporations care about, and what average consumer preferences look like. We have the information necessary for creating a powerful step function in demand within the VCM. Now we need highly driven individuals to thoughtfully develop and implement solutions. At <a href="https://carbonsmith.io/" target="_blank" rel="noopener noreferrer">Carbonsmith</a>, this is exactly what we are working towards. <i>The idea</i> of a marketplace is to connect buyers and sellers on a platform, but that platform does not need to be rigid in definition and form. We can use a multitude of products to reach different markets and stimulate more demand until we capture the true preferences of consumers. This will take some trial and error, but the underlying logic for success only rests on an assumption that <i>people care about sustainability</i> - which is backed by a plethora of existing studies and products on the market. As long as we move quickly and iterate when we find we are capturing a new share of consumer preferences, we will generate a substantial impact on the growth of the VCM.
        </PostParagraph>

        <PostParagraph>
        Solar and wind may not be apt comparisons for carbon removal. I like to make the analogy more closely to electric vehicles. Elon Musk is a polarizing figure nowadays, but what he did for EV's is undeniable. His eccentricity and personality gave the industry its first spark on a mainstream scale. We need to foster an environment in carbon removal that encourages breaking away from uniformity in the interest of creating a better environment. It's going to be those that think differently and market novel solutions with charisma that will build demand in the market for carbon removal. If we can combine innovative products and magnetic personalities with the continued growth of more traditional tools and wrap it in authenticity of high-quality solutions, we just might manufacture the perfect storm of conditions for the overnight success of carbon removal.
        </PostParagraph>
        <br />
        <br />


      </PostContainer>
    </div>
  );
}

export default StimulatingVCMPost;