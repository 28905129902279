import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './App.css';
import { faGithub, faInstagram, faKaggle, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';


class Links extends React.Component{


    render(){
    return (
        <div className='LinksFullContainer'>
            <h2 className='LinksHeader'>Connect with me!</h2>

            <div className='social-container'>
            {/* github, twitter, insta, linkedin, kaggle */}
                <div className='social-indiv'>
                    <a target='_blank' href='https://twitter.com/drewhahn23' rel='noopener noreferrer' className='twitter'>
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                </div>
                <div className='social-indiv'>
                    <a target='_blank' href='https://www.linkedin.com/in/andrew-hahn-3bb5749a/' rel='noopener noreferrer' className='linkedin'>
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                </div>
                <div className='social-indiv'>
                    <a target='_blank' href='https://github.com/drewhahn23' rel='noopener noreferrer' className='github'>
                        <FontAwesomeIcon icon={faGithub} />
                    </a>
                </div>
                <div className='social-indiv'>
                    <a target='_blank' href='https://www.instagram.com/drewhahn/?hl=en' rel='noopener noreferrer' className='instagram'>
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                </div>
                <div className='social-indiv'>
                    <a target='_blank' href='https://www.kaggle.com/drewhahn' rel='noopener noreferrer' className='kaggle'>
                        <FontAwesomeIcon icon={faKaggle} />
                    </a>
                </div>
                

            </div>



        </div>
    );
}
}

export default Links;