import React, { useState, useEffect } from 'react';
import { 
    Link
} from 'react-router-dom';
import axios from 'axios';
// import './ThreeJs';
// import SolarSystem from './ThreeJs';


const PlanetDisplay = () => {
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const loadPost = async () => {

            // console.log('called')

            //Till the data is fetched using API loading will show
            setLoading(true);

            const response = await axios.get(
                "https://space-api-drew.herokuapp.com/"

            );

            // console.log('loaded')
            // console.log(response)

            //After fetching data store in posts state

            setPosts(response.data);

            //close loading
            setLoading(false);
        }

        //call the func
        loadPost();
    }, []);
    //each posts object is a descriptor with a dictionary of kvps, 0,1,2,3... (enumerated planets) as keys, 
    //values as associated values for each planet
    //can either break down by key/value or add to separate object and iterate?
    // console.log(posts)

    return (
            <div className = "Planet">

                {/* <h2 className='Planet'>Planetary Data</h2> */}
                {/* <h5 className='Planet'>Using a few different sources, I have created a REST API that contains dynamic data relating to planets in our solar system...</h5> */}
                { loading ? (
                    <h4>Loading planetary data...</h4>) : 

 
                    (Object.entries(posts).map(([key, val])=> {
 
                   
                        return (
                            <div>
                            <ol key = {key} className='Planet'>
                                
                                <ol>Planet: {key}</ol>
                                <ol>Current Distance from Earth: {val["Distance from Earth (mi.)"]} miles</ol>
                                <ol>Equatorial Radius: {val["Equatorial Radius (km)"]} km</ol>
                                <ol>Planet Density: {val.Density} g/cm^3</ol>
                                <ol>Aphelion: {val.Aphelion} miles</ol>
                                <ol>Perihelion: {val.Perihelion} miles</ol>
                                <ol>G-Force: {val.Gravity} G</ol>
                                <ol>Years to Reach at Current Distance: <strong>{val['Years to Reach']} years</strong></ol>
                            </ol>
                            {/* doesnt take up full width of page? also only appears at bottom of page */}
                            {/* <SolarSystem></SolarSystem> */}
                            </div>

                        )
                    },


                    )
                    )

                    
                    }
                        <br />
                        <Link to='/'><p className='Back'>Back</p></Link>
   
                            {/* <Route exact path='/' /> */}

                        <ol className='Planet'>If you scroll to the bottom of the page, you can see a canvas model of our solar system that is near true to proportion</ol>
                        
                    </div>
                );
                
            
    
}




export default PlanetDisplay;