import React from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import './App.css';
import Homepage from './components/Homepage';
import Post from './components/Post';
import PlanetDisplay from './components/PlanetTracker';
import DecPostFull from './components/BlogPosts/DecPostFull';
import StimulatingVCMPost from './components/BlogPosts/StimulatingVCMPost/StimulatingVCMPost';

export default function App() {
  
  return (
 
    <Router>

      <Routes>

        <Route path='/' element={<Homepage />} />

        <Route path='/PlanetaryData' element={<PlanetDisplay />} />

        <Route path='/Post' element={<Post />} />

        <Route path='/Jan2024' element={<DecPostFull />} />

        <Route path='/Stimulating-the-Voluntary-Carbon-Market' element={<StimulatingVCMPost />} />
      </Routes>


    </Router>

  );
}


