import React from 'react';


class Projects extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            count: 0

        };
    }
    render(){

        return (
            <div>
                <h1>Recent Works</h1>


            </div>
        )
    }

}

export default Projects;